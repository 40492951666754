// custom-header.js
const Inline = Quill.import('blots/inline');
const Block = Quill.import('blots/block');

class CustomHeader extends Block {
  static create(value) {
    let node = super.create();
    node.setAttribute('data-header', value);
    return node;
  }

  static formats(node) {
    return node.getAttribute('data-header');
  }

  format(name, value) {
    if (name === this.statics.blotName && value) {
      this.domNode.setAttribute('data-header', value);
    } else {
      super.format(name, value);
    }
  }
}

CustomHeader.blotName = 'customHeader';
CustomHeader.tagName = ['H1', 'H2'];
Quill.register(CustomHeader);
